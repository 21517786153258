import { makeStyles } from '@mui/styles';
import { theme } from 'theme';

export const useStyles = makeStyles({
  secondaryHeaderContent: {
    backgroundColor: theme.palette.primary[900],
    height: '65px',
    gridArea: 'header',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  newSecondaryHeaderContent: {
    backgroundColor: `${theme.palette.primary[1100]}!important`,
  },
  secondaryHeaderMenu: {
    position: 'fixed',
    left: 365,
    marginTop: 13,
    spacing: 12,
    justifyContent: 'space-between',
  },
  chip: {
    color: 'white',
  },
  secondaryHeaderText: {
    lineHeight: 1,
    padding: theme.spacing(1),
    paddingRight: theme.spacing(4),
    fontSize: '18px',
    fontWeight: 500,
    '& > button': {
      backgroundColor: 'transparent',
      border: 'none',
      color: 'inherit',
      fontSize: 'inherit',
      fontWeight: 'inherit',
      fontFamily: 'inherit',
      cursor: 'pointer',
    },
    '& > button:hover': {
      color: theme.palette.secondary.main,
    },
  },
  SecondaryHeaderSubtitleText: {
    lineHeight: 1.5,
    paddingRight: theme.spacing(0.5),
    '& > button': {
      backgroundColor: 'transparent',
      border: 'none',
      color: 'inherit',
      fontSize: 'inherit',
      fontWeight: 'inherit',
      fontFamily: 'inherit',
      cursor: 'pointer',
    },
    '& > button:hover': {
      color: theme.palette.secondary.main,
    },
  },
  buttonsContent: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    marginRight: '10px',
    color: 'white',
  },
  icon: {
    marginRight: '5px',
    display: 'flex',
    alignItems: 'center',
  },
});
