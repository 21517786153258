import { PermGuard } from 'components/Guards/PermGuard';
import { LayoutArea } from 'components/LayoutArea';
import { useStyles } from 'components/LayoutPageContent';
import { SecondaryHeader } from 'components/SecondaryHeader';
import { SecondaryNav } from 'components/SecondaryNav';
import { SecondaryNavItem } from 'components/SecondaryNavItem';
import { Sidebar } from 'components/Sidebar/Sidebar';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Collapse, Typography } from '@mui/material';
import { WidgetDivider } from 'components/WidgetDivider/WidgetDivider';
import { ActivityIcon } from 'components/Icons/ActivityIcon';
import {
  PlayIconCircle,
  GridIcon,
  LayersIcon,
  ShieldIcon,
  UserIcon,
  UsersIcon,
  SquareCheckIcon,
  GearIcon,
  FiledImageIcon,
  MapFileIcon,
  FileCheckIcon,
  CurrencyDollarCircle,
} from 'components/Icons';
import { SideBarDetail } from 'components/SideBarDetail/SideBarDetail';
import { useDispatch, useSelector } from 'react-redux';
import { setMenu } from 'slices/authReducer';
import { Permissions } from '../../lib/permissions';
import { CollapseButton } from 'components/CollapseButton/CollapseButton';
import { CollapseNavItem } from 'components/CollapseNavItem/CollapseNavItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';

const styles = (variant) => ({
  overflowY: 'auto',
  ...(variant === 'map' && { display: 'flex', flexDirection: 'column' }),
});

const overviewScopes = [Permissions.ACCOUNT_DASHBOARD];
const adminScopes = [Permissions.ACCOUNT_USER_GRANT];
const orgScopes = [Permissions.ORG_MODIFY];
const userScopes = [Permissions.ACCOUNT_USER_MODIFY, Permissions.ACCOUNT_USER_GRANT];
const teamScopes = [Permissions.TEAM_DELETE, Permissions.TEAM_MODIFY, Permissions.ORG_TEAM_CREATE];
const projectScopes = [Permissions.PROJ_MODIFY, Permissions.ORG_PROJ_CREATE];
const contentScopes = [Permissions.PROJ_MODIFY_CONTENT];
const securityScopes = [Permissions.ACCOUNT_MODIFY];
const inspectionScopes = [Permissions.ACCOUNT_MODIFY];
// const globalScopes = [Permissions.SUPERADMIN_BILLING];

export const AccountSettingsContainer = ({
  title,
  showSidebar,
  hideMenu,
  sidebarConfig,
  children,
  subtitle,
  actions,
  variant,
  newStyle,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const location = useLocation();
  const { pathname } = location;
  const { admin } = useSelector((state) => state.auth.menu);
  const [submenu, setSubmenu] = useState();
  const match = (path) => pathname.indexOf(path) === 0;

  const iconSize = 20;

  useEffect(
    () => () => {
      dispatch(setMenu({ admin: true }));
    },
    []
  );

  useEffect(() => {
    if (pathname.startsWith('/admin/external/')) {
      setSubmenu('ext_menu');
    }
  }, [pathname]);

  return (
    <div className={classes.content}>
      {!hideMenu && admin && (
        <SecondaryNav>
          <Typography variant="h5" mb={1}>
            ACCOUNT ADMINISTRATION
          </Typography>
          <PermGuard scopes={overviewScopes}>
            <SecondaryNavItem exact to="/admin" active={pathname === '/admin'}>
              <ActivityIcon size={iconSize} />
              <div style={{ display: 'inline', verticalAlign: 'top', marginLeft: 10 }}> Overview</div>
            </SecondaryNavItem>
          </PermGuard>
          <PermGuard scopes={orgScopes}>
            <SecondaryNavItem exact to="/admin/organizations" active={match('/admin/organizations')}>
              <GridIcon size={iconSize} />
              <div style={{ display: 'inline', verticalAlign: 'top', marginLeft: 10 }}> Organizations</div>
            </SecondaryNavItem>
          </PermGuard>
          <PermGuard scopes={adminScopes}>
            <SecondaryNavItem exact to="/admin/admins" active={match('/admin/admins')}>
              <ShieldIcon size={iconSize} />
              <div style={{ display: 'inline', verticalAlign: 'top', marginLeft: 10 }}> Account Admins</div>
            </SecondaryNavItem>
          </PermGuard>
          <PermGuard scopes={userScopes}>
            <SecondaryNavItem exact to="/admin/users" active={match('/admin/users')}>
              <UserIcon size={iconSize} />
              <div style={{ display: 'inline', verticalAlign: 'top', marginLeft: 10 }}> Account Members</div>
            </SecondaryNavItem>
          </PermGuard>
          <PermGuard scopes={projectScopes}>
            <SecondaryNavItem exact to="/admin/projects" active={match('/admin/projects')}>
              <LayersIcon size={iconSize} />
              <div style={{ display: 'inline', verticalAlign: 'top', marginLeft: 10 }}> Projects</div>
            </SecondaryNavItem>
          </PermGuard>
          <PermGuard scopes={teamScopes}>
            <SecondaryNavItem exact to="/admin/teams" active={match('/admin/teams')}>
              <UsersIcon size={iconSize} />
              <div style={{ display: 'inline', verticalAlign: 'top', marginLeft: 10 }}> Teams</div>
            </SecondaryNavItem>
          </PermGuard>
          <PermGuard scopes={contentScopes}>
            <SecondaryNavItem exact to="/admin/content" active={match('/admin/content')}>
              <PlayIconCircle size={iconSize} />
              <div style={{ display: 'inline', verticalAlign: 'top', marginLeft: 10 }}> Content</div>
            </SecondaryNavItem>
          </PermGuard>
          <WidgetDivider />
          <PermGuard scopes={contentScopes}>
            <SecondaryNavItem
              exact
              to="/admin/failed-imported-content"
              active={match('/admin/failed-imported-content')}
            >
              <FiledImageIcon size={iconSize} />
              <div style={{ display: 'inline', verticalAlign: 'top', marginLeft: 10 }}> Failed Imports</div>
            </SecondaryNavItem>
          </PermGuard>
          <PermGuard scopes={adminScopes}>
            <SecondaryNavItem exact to="/admin/map/gis" active={match('/admin/map/gis')}>
              <MapFileIcon size={iconSize} />
              <div style={{ display: 'inline', verticalAlign: 'top', marginLeft: 10 }}> Map GIS / Services</div>
            </SecondaryNavItem>
          </PermGuard>
          <CollapseButton
            active={submenu === 'ext_menu'}
            onClick={() => setSubmenu(submenu !== 'ext_menu' ? 'ext_menu' : null)}
          >
            <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
            <div style={{ display: 'inline', verticalAlign: 'top', marginLeft: 10 }}> External Resources</div>
          </CollapseButton>
          <Collapse in={submenu === 'ext_menu'} timeout="auto" unmountOnExit>
            <PermGuard scopes={adminScopes}>
              <CollapseNavItem exact to="/admin/external/users" active={match('/admin/external/users')}>
                <UserIcon size={iconSize} />
                <div style={{ display: 'inline', verticalAlign: 'top', marginLeft: 10 }}> External Users</div>
              </CollapseNavItem>
            </PermGuard>
            <PermGuard scopes={adminScopes}>
              <CollapseNavItem exact to="/admin/external/projects" active={match('/admin/external/projects')}>
                <LayersIcon size={iconSize} />
                <div style={{ display: 'inline', verticalAlign: 'top', marginLeft: 10 }}> External Projects</div>
              </CollapseNavItem>
            </PermGuard>
            <PermGuard scopes={adminScopes}>
              <CollapseNavItem exact to="/admin/external/teams" active={match('/admin/external/teams')}>
                <UsersIcon size={iconSize} />
                <div style={{ display: 'inline', verticalAlign: 'top', marginLeft: 10 }}> External Teams</div>
              </CollapseNavItem>
            </PermGuard>
            <PermGuard scopes={adminScopes}>
              <CollapseNavItem exact to="/admin/external/content" active={match('/admin/external/content')}>
                <PlayIconCircle size={iconSize} />
                <div style={{ display: 'inline', verticalAlign: 'top', marginLeft: 10 }}> External Content</div>
              </CollapseNavItem>
            </PermGuard>
          </Collapse>
          <WidgetDivider />
          <PermGuard scopes={inspectionScopes}>
            <SecondaryNavItem exact to="/admin/inspections" active={match('/admin/inspections')}>
              <FileCheckIcon size={iconSize} />
              <div style={{ display: 'inline', verticalAlign: 'top', marginLeft: 10 }}> Inspection Templates</div>
            </SecondaryNavItem>
          </PermGuard>
          <PermGuard scopes={adminScopes}>
            <SecondaryNavItem exact to="/admin/sso" active={match('/admin/sso')}>
              <SquareCheckIcon size={iconSize} />
              <div style={{ display: 'inline', verticalAlign: 'top', marginLeft: 10 }}> SSO Settings</div>
            </SecondaryNavItem>
          </PermGuard>
          <PermGuard scopes={adminScopes}>
            <SecondaryNavItem exact to="/admin/accounting" active={match('/admin/accounting')}>
              <CurrencyDollarCircle size={iconSize} />
              <div style={{ display: 'inline', verticalAlign: 'top', marginLeft: 10 }}>Accounting</div>
            </SecondaryNavItem>
          </PermGuard>
          <PermGuard scopes={securityScopes}>
            <SecondaryNavItem exact to="/admin/settings" active={match('/admin/settings')}>
              <GearIcon size={iconSize} />
              <div style={{ display: 'inline', verticalAlign: 'top', marginLeft: 10 }}> Settings</div>
            </SecondaryNavItem>
          </PermGuard>
        </SecondaryNav>
      )}
      {showSidebar && <Sidebar config={sidebarConfig} visible />}
      <SideBarDetail />
      <LayoutArea area="content" variant={variant} styles={styles(variant)}>
        <SecondaryHeader title={title} actions={actions} subtitle={subtitle} newStyle={newStyle} />
        {children}
      </LayoutArea>
    </div>
  );
};

AccountSettingsContainer.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.any.isRequired,
  subtitle: PropTypes.string,
  showSidebar: PropTypes.bool,
  hideMenu: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  sidebarConfig: PropTypes.object,
  actions: PropTypes.instanceOf(Array),
  variant: PropTypes.string,
  newStyle: PropTypes.bool,
};

AccountSettingsContainer.defaultProps = {
  showSidebar: false,
  hideMenu: false,
  sidebarConfig: {},
  actions: [],
  variant: '',
  subtitle: '',
  newStyle: false,
};
