import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
  Button,
  TextField,
  LinearProgress,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LayersIcon from '@mui/icons-material/Layers';
import { LoadingOverlay } from 'components/LoadingOverlay/LoadingOverlay';
import LinkedFilesService from 'services/LinkedFilesService';
import { DeleteIcon } from 'components/Icons/DeleteIcon';
import { FilePageIcon } from 'components/Icons/FilePageIcon';

const UploadBox = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: 10,
  padding: theme.spacing(3),
  textAlign: 'center',
  cursor: 'pointer',
  '&:hover': {
    borderColor: theme.palette.grey[400],
  },
}));

const FileItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1),
  marginTop: theme.spacing(1),
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: 10,
  '&:hover': {
    borderColor: theme.palette.grey[100],
    backgroundColor: theme.palette.action.hover,
  },
}));

const validationSchema = Yup.object().shape({
  uuid: Yup.string().matches(/^[0-9a-fA-F-]{36}$/, 'Invalid UUID format'),
  files: Yup.array(),
});

const formatFileSize = (bytes) => {
  if (bytes === 0) return '0 B';
  const k = 1024;
  const sizes = ['B', 'KB', 'MB', 'GB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / k ** i).toFixed(1))} ${sizes[i]}`;
};

export const LinkedFilesModal = ({ open, onClose, projectId, onFileUploaded }) => {
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      files: [],
      uuid: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        const uploadPromises = values.files.map(async (fileData) => {
          try {
            // step 1: get signed URL
            const uploadData = {
              name: fileData.file.name,
              description: '',
              fileType: fileData.file.type,
              projectId,
            };

            // get the response
            const response = await LinkedFilesService.generateUploadUrl(uploadData);

            const {
              data: { data },
            } = response;
            const { id, signedUrl } = data.files[0];

            // step 2: upload to GCS directly
            const uploadSuccess = await LinkedFilesService.uploadToSignedUrl(signedUrl, fileData.file);

            if (uploadSuccess) {
              // step 3: notify backend of completion
              await LinkedFilesService.completeUpload([
                {
                  id,
                  ext: fileData.file.name.split('.').pop(),
                },
              ]);

              // update progress in UI
              const newFiles = [...formik.values.files];
              const fileIndex = newFiles.findIndex((f) => f.file.name === fileData.file.name);
              if (fileIndex !== -1) {
                newFiles[fileIndex].progress = 100;
                newFiles[fileIndex].completed = true;
                formik.setFieldValue('files', newFiles);
              }
            }
          } catch (error) {
            console.error(`Error uploading file ${fileData.file.name}:`, error);
            throw error;
          }
        });

        await Promise.all(uploadPromises);
        onFileUploaded(); // refresh the file list
        onClose();
      } catch (error) {
        console.error('Error uploading files:', error);
      } finally {
        setIsLoading(false);
      }
    },
  });

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFiles = Array.from(event.dataTransfer.files);
    formik.setFieldValue('files', [
      ...formik.values.files,
      ...droppedFiles.map((file) => ({
        file,
        progress: 0,
        completed: false,
      })),
    ]);
  };

  const handleFileSelect = (event) => {
    const selectedFiles = Array.from(event.target.files);
    formik.setFieldValue('files', [
      ...formik.values.files,
      ...selectedFiles.map((file) => ({
        file,
        progress: 0,
        completed: false,
      })),
    ]);
  };

  const handleRemoveFile = (index) => {
    const newFiles = [...formik.values.files];
    newFiles.splice(index, 1);
    formik.setFieldValue('files', newFiles);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth aria-labelledby="linked-files-modal-title">
      {isLoading && <LoadingOverlay />}
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <LayersIcon />
          <Typography variant="h5" component="span" sx={{ ml: 1 }}>
            Add Linked File
          </Typography>
        </Box>
      </DialogTitle>

      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Typography gutterBottom sx={{ color: '#B1B1B1' }}>
            Select the file(s) you would like to upload and link to the project.
          </Typography>
          <UploadBox
            onDrop={handleDrop}
            onDragOver={(e) => e.preventDefault()}
            onClick={() => document.getElementById('file-input').click()}
          >
            <input
              type="file"
              id="file-input"
              multiple
              style={{ display: 'none' }}
              onChange={handleFileSelect}
              accept=".pdf,.doc,.docx,.xls,.xlsx,.txt,.csv,.zip,.rar"
            />
            <CloudUploadIcon sx={{ fontSize: 48, color: 'secondary', mb: 1 }} />
            <Typography color="secondary" sx={{ fontWeight: 'bolder' }}>
              Click to upload
            </Typography>
            <Typography sx={{ fontWeight: 'bold', color: '#B1B1B1' }}>or drag and drop</Typography>
            <Typography variant="caption" sx={{ mt: 1 }}>
              Supported formats: PDF, DOC, DOCX, XLS, XLSX, TXT, CSV, ZIP, RAR
            </Typography>
          </UploadBox>

          {formik.values.files.map((fileData, index) => (
            <FileItem key={fileData.file.name}>
              <FilePageIcon type={fileData.file.type} size={48} className="fit" />
              <Box sx={{ mx: 2, flexGrow: 1 }}>
                <Typography variant="body2" noWrap maxWidth="50%">
                  {fileData.file.name}
                </Typography>
                <Typography variant="caption" maxWidth="100%" noWrap>
                  {formatFileSize(fileData.file.size)}
                </Typography>
                <LinearProgress variant="determinate" value={fileData.progress} sx={{ mt: 1, maxWidth: '100%' }} />
              </Box>
              {fileData.completed ? (
                <CheckCircleIcon color="success" sx={{ ml: 1 }} />
              ) : (
                <IconButton size="small" onClick={() => handleRemoveFile(index)} sx={{ ml: 1 }}>
                  <DeleteIcon size={16} fill="#fff" />
                </IconButton>
              )}
            </FileItem>
          ))}

          <Box sx={{ mt: 3 }}>
            <Typography variant="subtitle1" gutterBottom>
              Add Via UUID
            </Typography>
            <TextField
              fullWidth
              name="uuid"
              placeholder="Paste UUID Number"
              value={formik.values.uuid}
              onChange={formik.handleChange}
              error={formik.touched.uuid && Boolean(formik.errors.uuid)}
              helperText={formik.touched.uuid && formik.errors.uuid}
              variant="outlined"
              size="small"
            />
          </Box>
        </DialogContent>

        <DialogActions>
          <Button variant="contained" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            type="submit"
            disabled={isLoading || (formik.values.files.length === 0 && !formik.values.uuid)}
          >
            {isLoading ? 'Uploading...' : 'Confirm'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

LinkedFilesModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  onFileUploaded: PropTypes.func.isRequired,
};

export default LinkedFilesModal;
