/**
 * Class to get the labels for the kml object wheter point or line files.
 */
import { v4 } from 'uuid';

class SamKMLLayer {
  /**
   * Global variables
   */
  url = '';

  type = '';

  sourceJSON = {};

  isResolved = false;

  /**
   * The constructor receive an object,
   * for this momento only is received the URL for the object
   * @param {Object} url
   */
  constructor({ url }) {
    this.url = url;
    this.validateURL();
  }

  /**
   * This function validate the correct URL extension to allow kml and kmz files
   */
  validateURL() {
    if (this.url === '' || !this.url.length || !String(this.url)) {
      throw new Error('The URL should not be empty or bad format.');
    }
    const pathName = new URL(this.url)?.pathname;
    const lastDot = pathName.lastIndexOf('.');
    const type = pathName.substring(lastDot + 1);
    this.type = type;
    if (!['kml', 'kmz'].includes(type)) {
      throw new Error('The file extension must be `kml` or `kmz`');
    }
  }

  /**
   * This function receive a text - xml value and will return a new object
   * with the XML structure
   * @param {string} xml
   * @returns new object
   */
  convertXMLToJSON(xml) {
    let json = {};
    if (xml.children.length > 0) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < xml.children.length; i++) {
        const item = xml.children.item(i);
        const { nodeName } = item;

        if (typeof json[nodeName] === 'undefined') {
          json[nodeName] = this.convertXMLToJSON(item);
        } else {
          if (typeof json[nodeName].push === 'undefined') {
            const old = json[nodeName];
            json[nodeName] = [];
            json[nodeName].push(old);
          }
          json[nodeName].push(this.convertXMLToJSON(item));
        }
      }
    } else {
      json = xml.textContent;
    }
    return json;
  }

  /**
   * This function will process the parsed object applying the necesary
   * process to get the information, in this case return and object like the
   * following example.
   * ```jsx
   * {
   *  folderName: 'default' | 'Name in the JSON',
   *  items: [
   *    {
   *      description: 'Name of the object',
   *      center: {
   *        longitude: -0.0000, // Longitude of the object
   *        latitude: 0.0000 // Latitude of the object
   *      }
   *    }
   *  ]
   * }
   * ```
   * @returns new object with the folder name and items
   */
  processData() {
    if (!Object.keys(this.sourceJSON).length) {
      throw new Error('The JSON parsed is empty');
    }
    if (!Object.keys(this.sourceJSON).length || !this.sourceJSON?.kml) {
      throw new Error(`The source JSON not contains a right structure: ${this.sourceJSON}`);
    }
    const layers = {
      folderName: 'default',
      items: [],
    };
    const { Placemark, name: folderName } = this.sourceJSON?.kml?.Document?.Folder;
    if (folderName) {
      layers.folderName = folderName;
    }
    if (Placemark) {
      // validate if placemarker is an array
      if (Array.isArray(Placemark)) {
        // validate the placemark content to know if the object is a `point` or `line`
        Array.from(Placemark).forEach((item) => {
          if (item?.Point) {
            // this is a point
            const { Point, description, name: pointName } = item;
            const { coordinates } = Point;
            const [longitude, latitude] = String(coordinates).split(',');
            layers.items.push({
              id: v4(),
              description: description || pointName,
              center: {
                longitude: parseFloat(longitude),
                latitude: parseFloat(latitude),
              },
            });
          } else if (item?.LineString) {
            // this is a line
            const simpleData = item?.ExtendedData?.SchemaData?.SimpleData;
            const { coordinates } = item?.LineString;
            const [firstCoordinates] = String(coordinates).split(' ');
            const [longitude, latitude] = String(firstCoordinates).split(',');
            layers.items.push({
              id: v4(),
              description: simpleData ? simpleData[0] : 'Default description',
              center: {
                longitude: parseFloat(String(longitude).trim()),
                latitude: parseFloat(String(latitude).trim()),
              },
            });
          }
        });
      } else if (Placemark?.Point) {
        // validate the placemark content to know if the object is a `point` or `line`
        // this is a point
        const { Point, description } = Placemark;
        const { coordinates } = Point;
        const [longitude, latitude] = String(coordinates).split(',');
        layers.items.push({
          id: v4(),
          description,
          center: {
            longitude: parseFloat(longitude),
            latitude: parseFloat(latitude),
          },
        });
      } else if (Placemark?.LineString) {
        // this is line
        const simpleData = Placemark?.ExtendedData?.SchemaData?.SimpleData;
        const { coordinates } = Placemark?.LineString;
        const [firstCoordinates] = String(coordinates).split(' ');
        const [longitude, latitude] = String(firstCoordinates).split(',');
        layers.items.push({
          id: v4(),
          description: simpleData ? simpleData[0] : 'Default description',
          center: {
            longitude: parseFloat(String(longitude).trim()),
            latitude: parseFloat(String(latitude).trim()),
          },
        });
      }
    }
    this.isResolved = true;
    return layers;
  }

  /**
   * This is the main function to return the object processed
   * @returns Promise
   */
  when() {
    return fetch(this.url)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Problems fetching the file.');
        }
        if (['kmz'].includes(this.type)) {
          return response.arrayBuffer();
        }
        return response.text();
      })
      .then((response) => {
        const parser = new DOMParser();
        const xml = parser.parseFromString(response, 'text/xml');
        const json = this.convertXMLToJSON(xml);
        if (!json?.kml) {
          window.console.error(json?.html?.body?.parsererror?.div);
          return null;
        }
        // assign to the global variable in the class
        this.sourceJSON = json;
        return this.processData();
      })
      .catch((error) => {
        window.console.log(error);
        throw new Error(error);
      });
  }
}

export default SamKMLLayer;
