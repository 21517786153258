import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  TextField,
  Grid,
  Typography,
  Select,
  MenuItem,
  FormControl,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
} from '@mui/material';
import { FilterIcon, SearchIcon, CirclePlusIcon, AlertTriangleIcon } from 'components/Icons';
import { useSidebar } from 'components/SidebarProvider/SidebarProvider';
import { setFilters } from 'slices/linkedFilesReducer';
import { SearchPanel } from './SearchPanel/SearchPanel';
import { useStyles } from '../styles';
import { LinkedFilesModal } from './LinkedFilesModal';
import LinkedFilesService from 'services/LinkedFilesService';

const bulkActions = [
  {
    label: 'Attach to Content',
    value: 'attach',
  },
  {
    label: 'Delete from Project',
    value: 'delete',
  },
];

export const LinkedFilesFilters = ({ detail, projectId, onFileUploaded, selectedRows = [] }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { filters } = useSelector((state) => state.linkedFiles);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAction, setSelectedAction] = useState('');
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const { displayPanel, setFullWidth } = useSidebar({
    open: false,
    config: {
      onClose: () => {},
      search: {
        show: true,
        component: SearchPanel,
        background: true,
      },
    },
  });

  const handleOpenFilters = () => {
    setFullWidth(false);
    displayPanel('search');
  };

  const filterOptions = (value) => {
    dispatch(
      setFilters({
        search: value,
      })
    );
  };

  const onAddNew = () => {
    setIsModalOpen(true);
  };

  const handleActionChange = (event) => {
    switch (event.target.value) {
      case 'attach':
        break;
      case 'delete':
        setShowDeleteConfirm(true);
        break;
      default:
        break;
    }
    setSelectedAction(event.target.value);
  };

  const handleBulkDelete = async () => {
    try {
      await LinkedFilesService.bulkRemove({ ids: selectedRows });
      setShowDeleteConfirm(false);
      setSelectedAction('');
      if (onFileUploaded) {
        onFileUploaded();
      }
    } catch (error) {
      console.error('Error deleting files:', error);
    }
  };

  return (
    <div className={classes.header}>
      <LinkedFilesModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        projectId={projectId}
        onFileUploaded={onFileUploaded}
      />
      <Grid container spacing={0.5}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            {detail ? (
              <>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={onAddNew}
                  sx={{ color: 'white', margin: 0.5, width: '300px' }}
                >
                  <CirclePlusIcon size={20} />
                  <Typography ml={0.2}>Add New</Typography>
                </Button>
                <FormControl>
                  <Select
                    value={selectedAction}
                    onChange={handleActionChange}
                    displayEmpty
                    disabled={selectedRows.length === 0}
                    sx={{
                      width: '200px',
                      height: '40px',
                      backgroundColor: 'transparent',
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(255, 255, 255, 0.23)',
                      },
                      '& .MuiSelect-select': {
                        color: 'white',
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(255, 255, 255, 0.23)',
                      },
                    }}
                  >
                    <MenuItem value="" disabled>
                      <Typography>Bulk Actions</Typography>
                    </MenuItem>
                    {bulkActions.map((action) => (
                      <MenuItem key={action.value} value={action.value}>
                        {action.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px', marginLeft: 'auto' }}>
                  <TextField
                    variant="outlined"
                    placeholder="Search"
                    InputProps={{
                      startAdornment: <SearchIcon size={20} />,
                    }}
                    value={filters.search}
                    onChange={(e) => filterOptions(e.target.value)}
                    sx={{
                      minWidth: '300px',
                      '& .MuiOutlinedInput-root': {
                        height: '40px',
                        backgroundColor: 'transparent',
                        '& fieldset': {
                          borderColor: 'rgba(255, 255, 255, 0.23)',
                        },
                        '&:hover fieldset': {
                          borderColor: 'rgba(255, 255, 255, 0.23)',
                        },
                        '& input': {
                          color: 'white',
                        },
                      },
                    }}
                  />
                  <Button
                    size="medium"
                    color="primary"
                    variant="contained"
                    className={`${classes.filterButton} ${filters.activated && classes.activatedFilter}`}
                    onClick={() => handleOpenFilters()}
                    sx={{ marginRight: 0.5, width: '200px' }}
                  >
                    <FilterIcon size={15} /> Filters {filters.activated && 'On'}
                  </Button>
                </div>
              </>
            ) : (
              <>
                <Typography variant="h4">Linked Files</Typography>
                <Typography>Manage and organize files linked to this project.</Typography>
              </>
            )}
          </div>
        </Grid>
      </Grid>
      <Dialog open={showDeleteConfirm} onClose={() => setShowDeleteConfirm(false)}>
        <Box sx={{ mt: 1, ml: 1 }}>
          <AlertTriangleIcon size={40} />
        </Box>
        <DialogTitle>
          <Typography variant="h4">Are you sure?</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography gutterBottom sx={{ fontWeight: 'normal', color: '#B1B1B1' }}>
            This action cannot be undone. Are you sure you want to remove {selectedRows.length}{' '}
            {selectedRows.length === 1 ? 'file' : 'files'}?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              setShowDeleteConfirm(false);
              setSelectedAction('');
            }}
          >
            Cancel
          </Button>
          <Button onClick={handleBulkDelete} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

LinkedFilesFilters.propTypes = {
  detail: PropTypes.bool,
  projectId: PropTypes.string.isRequired,
  onFileUploaded: PropTypes.func.isRequired,
  selectedRows: PropTypes.array,
};

LinkedFilesFilters.defaultProps = {
  detail: false,
  selectedRows: [],
};
