import React, { useEffect, useRef } from 'react';
import MapView from '@arcgis/core/views/MapView';
import Map from '@arcgis/core/Map';
import esriConfig from '@arcgis/core/config';
import KMLLayer from '@arcgis/core/layers/KMLLayer';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import Graphic from '@arcgis/core/Graphic';
import { useStyles } from './styles';
import SamKMLLayer from 'lib/samkmllayer';
import PropTypes from 'prop-types';

// export const MapViewComponent = ({ mapData, webMapId }) => {
//   const [mapView, setMapView] = useState(null);
//   const mapDivRef = useRef(null);
//   // const esriId = new IdentityManager();
//   // let mapView;
//   let isMounted = true;

//   useEffect(() => {
//     if (isMounted) {
//       // Generate a token
//       IdentityManager.generateToken({
//         server: 'https://scrates-medina.maps.arcgis.com',
//         username: 'smedina_dh',
//         password: 'ZjzKTpP9mT34dje',
//         expiration: 1440,
//       })
//         .then((response) => {
//           // Use the token to create the WebMap
//           esriConfig.portalUrl = 'https://scrates-medina.maps.arcgis.com';
//           esriConfig.token = response.token;

//           // Check if the token has expired
//           const expirationTime = new Date(response.expires);
//           const currentTime = new Date();
//           if (expirationTime < currentTime) {
//             console.info('Token has expired');
//           } else {
//             console.info('Token is still valid');
//           }

//           const map = new WebMap({ portalItem: { id: webMapId } });

//           // Create a new view
//           const newMapView = new MapView({
//             container: mapDivRef.current,
//             map,
//             center: mapData.center || [-118.244, 34.052], // Default to Los Angeles if not provided
//             zoom: mapData.zoom || 14, // Default zoom level if not provided
//           });

//           // Set the mapView state
//           setMapView(newMapView);
//         })
//         .catch((error) => {
//           console.error('Error generating token:', error);
//         });
//     }

//     return () => {
//       if (mapView) {
//         isMounted = false;
//         mapView.destroy();
//       }
//     };
//   }, []);

//   return <div ref={mapDivRef} style={{ width: '100%', height: '40vh' }} />;
// };

export const MapViewComponent = ({ mapData, webMapId }) => {
  const mapDiv = useRef(null);
  const classes = useStyles();
  const zoom = 14;

  const createKML = (kml) => {
    const kmls = [];
    if (kml?.mapRootUrl) {
      kmls.push(
        new KMLLayer({
          id: kml.id,
          url: kml.mapRootUrl,
        })
      );
    }
    return kmls;
  };

  // got to the center
  const defaultZoom = (view, center) => {
    view.when().then(() => {
      view.goTo({
        center: [...center],
        zoom,
      });
    });
  };

  // Setting the configured label in the kml record
  const displayLabel = (items, main = false) => {
    const nLayers = [];
    const source = [];
    if (items.length) {
      items.map((item) => {
        source.push(
          new Graphic({
            geometry: {
              type: 'point',
              x: item?.center?.longitude,
              y: item?.center?.latitude,
            },
            attributes: {
              id: item?.id,
              description: item?.description,
              DepArpt: 'KATL',
              MsgTime: new Date(),
              FltId: 'UAL1',
            },
          })
        );
        return item;
      });
      const layer = new FeatureLayer({
        source,
        objectIdField: 'id',
        fields: [
          {
            name: 'id',
            type: 'oid',
          },
          {
            name: 'description',
            type: 'string',
            defaultValue: 'Name here',
          },
        ],
        renderer: {
          type: 'simple',
          symbol: {
            type: 'simple-marker',
            size: 0,
            outline: null,
          },
        },
        labelingInfo: [
          {
            labelPlacement: 'above-center',
            minScale: 250000000,
            symbol: {
              type: 'text',
              font: {
                size: main ? 10 : 8,
                weight: 'bold',
              },
              horizontalAlignment: 'left',
              color: main ? '#F0CA23' : '#FFFFFF',
              haloColor: '#000000',
              haloSize: 1,
            },
            labelExpressionInfo: {
              expression: 'DefaultValue($feature.description, "no data")',
            },
          },
        ],
      });
      nLayers.push(layer);
    }
    return nLayers;
  };

  // on page load
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    /**
     * Initialize the map
     */
    esriConfig.apiKey = process.env.REACT_APP_ESRI_API_KEY;
    if (mapDiv.current) {
      /**
       * Creating the map
       */
      const map = new Map({
        basemap: 'hybrid',
        portalItem: { id: webMapId },
        layers: [...createKML(mapData)],
      });

      const view = new MapView({
        container: mapDiv.current,
        map,
      });

      // Add effect to zoom out to the points layer
      view.when().then(() => {
        const { layers } = view.map;
        const layer = layers.items[0];

        if (layer && layer?.extent) {
          const { latitude, longitude } = layer?.extent?.center;
          defaultZoom(view, [longitude, latitude]);
          map.addMany(
            displayLabel(
              [
                {
                  center: { latitude, longitude },
                  id: mapData?.id,
                  description: mapData?.mapDesiredLabel,
                },
              ],
              true
            )
          );

          if (mapData?.mapRootUrl) {
            const sam = new SamKMLLayer({
              url: mapData.mapRootUrl,
            });
            sam.when().then((items) => {
              if (sam.isResolved) {
                map.addMany(displayLabel(items.items));
              }
            });
          }
        } else {
          defaultZoom(view, [-106.5348, 38.7946]);
        }

        view.popup.autoOpenEnabled = false;
        view.popup.autoCloseEnabled = false;
        view.popup.dockEnabled = false;

        view.watch('updating', (value) => {
          if (!value) {
            layers.items.map((item) => {
              if (['graphics', 'kml'].includes(item.type)) {
                item.effect = [
                  {
                    scale: 10489297,
                    value: 'drop-shadow(0px, 0px, 50px, #FFC700) brightness(170%) blur(10px)',
                  },
                  {
                    scale: 2622324,
                    value: 'drop-shadow(2px, 2px, 3px) brightness(100%) blur(5px)',
                  },
                  {
                    scale: 108895.277144,
                    value: 'drop-shadow(0px, 0px, 0px)',
                  },
                ];
              }
              return item;
            });
          }
        });
      });

      return () => {
        view.destroy();
        map.destroy();
      };
    }
  }, [mapData]);

  return (
    <div className={classes.container}>
      <div ref={mapDiv} className={classes.map} id="mapContainer" />
    </div>
  );
};

// PropTypes for type checking
MapViewComponent.propTypes = {
  mapData: PropTypes.object,
  webMapId: PropTypes.oneOfType([
    PropTypes.string, // ArcGIS Enterprise web map ID
    PropTypes.shape({
      // ArcGIS Online web map with sharing link
      url: PropTypes.string.isRequired,
      itemId: PropTypes.string.isRequired,
    }),
  ]),
};

// DefaultProps for default values
MapViewComponent.defaultProps = {
  mapData: {},
  webMapId: null,
};
