import { makeStyles } from '@mui/styles';
import { theme } from 'theme';

export const useStyles = makeStyles({
  bar: (props) => ({
    backgroundColor: theme.palette.primary.dark,
    fontWeight: 'bold',
    borderTop: `1px solid ${theme.palette.primary[500]}`,
    padding: theme.spacing(0.5),
    display: 'flex',
    justifyContent: props.flex,
  }),
  input: {
    width: '300px',
    '& svg': {
      marginRight: `${theme.spacing(0.2)}!important`,
    },
    fontSize: 12,
    height: '50%',
    flex: 1,
  },
  select: {
    width: '300px',
  },
  filterButton: {
    marginLeft: `${theme.spacing(0.1)}!important`,
    height: 40,
    '& svg': {
      marginRight: `${theme.spacing(0.2)}!important`,
    },
  },
  button: {
    height: '40px',
    width: '44px',
    padding: '10px',
  },
  activatedFilter: {
    color: theme.palette.secondary[500],
    border: `1px solid ${theme.palette.secondary[500]}`,
  },
  filters: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '6px',
  },
  filtersButtonBar: {
    marginRight: theme.spacing(0.1),
  },
  credit: {
    backgroundColor: `${theme.palette.primary[1400]}!important`,
  },
  arcgis: {
    backgroundColor: `${theme.palette.primary[1700]}!important`,
  },
});
