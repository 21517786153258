import React, { useState, useMemo, useEffect } from 'react';
import { Typography, IconButton } from '@mui/material';
import { useTable, useSortBy } from 'react-table';
import { GeneralTable } from 'components/GeneralTable/GeneralTable';
import { ContextMenu } from 'components/ContextMenu/ContextMenu';
import { ContextMenuButton } from 'components/ContextMenuButton';
import { ContextMenuList } from 'components/ContextMenuItems/ContextMenuList';
import { ContextMenuItem } from 'components/ContextMenuItem';
import { WidgetDivider } from 'components/WidgetDivider/WidgetDivider';
import { useGlobalStyles } from 'styles';
import { Card } from 'components/Card';
import { useSelector, useDispatch } from 'react-redux';
import { setLinkedFiles } from 'slices/linkedFilesReducer';
import { LinkedFilesFilters } from './LinkedFilesFilters';
import LinkedFilesService from 'services/LinkedFilesService';
import { DownloadIcon } from 'components/Icons/DownloadIcon';
import { FilePageIcon } from 'components/Icons/FilePageIcon';

const downloadLinkedFile = async (fileId, type) => {
  try {
    const {
      data: { data },
    } = await LinkedFilesService.getDownloadUrl(fileId);
    const { downloadUrl } = data;

    // use fetch to get the file
    const result = await fetch(downloadUrl);
    const blob = await result.blob();

    // create URL for blob
    const url = window.URL.createObjectURL(blob);

    // create link and trigger download
    const link = document.createElement('a');
    link.href = url;
    link.download = `file-${fileId}.${type}`;
    document.body.appendChild(link);
    link.click();

    // cleanup
    window.URL.revokeObjectURL(url);
    document.body.removeChild(link);
  } catch (error) {
    console.error('Error downloading file:', error);
  }
};

const createTableColumns = (globalClasses) => [
  {
    Header: 'Name',
    accessor: ({ name, fileType: type }) => (
      <div className={globalClasses.nameContainer}>
        <FilePageIcon type={type} size={48} className="fit" />
        <Typography>{name}</Typography>
      </div>
    ),
  },
  {
    Header: 'Description',
    accessor: 'description',
  },
  {
    Header: 'Linked To',
    accessor: ({ projectId, projectAssetId }) => (
      <div>
        {projectId && <Typography wrap maxWidth={200}>{`Project ID: ${projectId}`}</Typography>}
        {projectAssetId && <Typography wrap maxWidth={200}>{`Project Asset ID: ${projectAssetId}`}</Typography>}
      </div>
    ),
  },
  {
    Header: 'Type',
    accessor: ({ fileType: type }) => <Typography>{type.split('/')[1].toUpperCase()}</Typography>,
  },
  {
    Header: 'Download',
    accessor: ({ id, fileType: type }) => (
      <IconButton onClick={() => downloadLinkedFile(id, type.split('/')[1])}>
        <DownloadIcon />
      </IconButton>
    ),
  },
];

export const LinkedFiles = ({ project }) => {
  const globalClasses = useGlobalStyles();
  const dispatch = useDispatch();
  const projectId = project?.id;
  // add default empty array to prevent undefined
  const { linkedFiles = [], filters } = useSelector((state) => state.linkedFiles);
  // eslint-disable-next-line no-unused-vars
  const [selectedRows, setSelectedRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const columns = useMemo(() => createTableColumns(globalClasses), [globalClasses]);

  const fetchLinkedFiles = async () => {
    try {
      setIsLoading(true);
      const {
        data: { data },
        status,
      } = await LinkedFilesService.getLinkedFiles({ projectId });

      if (status === 200) {
        dispatch(setLinkedFiles(data || []));
      }
    } catch (error) {
      console.error('Error fetching linked files:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchLinkedFiles();
  }, [projectId]);

  const handleOpenFile = async (fileId) => {
    try {
      const url = await LinkedFilesService.getDownloadUrl(fileId);
      window.open(url.data, '_blank');
    } catch (error) {
      console.error('Error opening file:', error);
    }
  };

  const handleDeleteFile = async (fileId) => {
    try {
      await LinkedFilesService.deleteLinkedFile(fileId);
      fetchLinkedFiles();
    } catch (error) {
      console.error('Error deleting file:', error);
    }
  };

  const handleUpdateFile = async (fileId) => {
    try {
      await LinkedFilesService.updateLinkedFile(fileId, projectId);
      fetchLinkedFiles();
    } catch (error) {
      console.error('Error updating file:', error);
    }
  };

  const filteredData = useMemo(() => {
    // ensure linkedFiles is always an array
    const files = Array.isArray(linkedFiles) ? linkedFiles : [];
    let filtered = [...files];

    if (filters.search) {
      const searchLower = filters.search.toLowerCase();
      filtered = filtered.filter(
        (item) =>
          item.name.toLowerCase().includes(searchLower) ||
          item.description?.toLowerCase().includes(searchLower) ||
          item.linkedTo?.toLowerCase().includes(searchLower)
      );
    }

    if (filters.fileType) {
      filtered = filtered.filter((item) => item.type === filters.fileType);
    }

    if (filters.sort) {
      switch (filters.sort) {
        case 'name_asc':
          filtered.sort((a, b) => a.name.localeCompare(b.name));
          break;
        case 'name_desc':
          filtered.sort((a, b) => b.name.localeCompare(a.name));
          break;
        case 'newest':
          filtered.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
          break;
        case 'oldest':
          filtered.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
          break;
        default:
          break;
      }
    }

    return filtered;
  }, [linkedFiles, filters]);

  const table = useTable(
    {
      columns,
      data: filteredData,
    },
    useSortBy
  );

  const ItemActions = ({ row }) => (
    <ContextMenu>
      <ContextMenuButton />
      <ContextMenuList position="bottom-right">
        <ContextMenuItem onClick={() => navigator.clipboard.writeText(row.original.id)}>Copy UUID</ContextMenuItem>
        <ContextMenuItem onClick={() => navigator.clipboard.writeText(row.original.linkedTo)}>
          Attach to Content
        </ContextMenuItem>
        <ContextMenuItem onClick={() => handleOpenFile(row.original.id)}>Open File</ContextMenuItem>
        <ContextMenuItem onClick={() => handleUpdateFile(row.original.id)}>Update File</ContextMenuItem>
        <WidgetDivider />
        <ContextMenuItem onClick={() => handleDeleteFile(row.original.id)}>Delete from Project</ContextMenuItem>
      </ContextMenuList>
    </ContextMenu>
  );

  return (
    <Card noPadding>
      <LinkedFilesFilters detail projectId={projectId} onFileUploaded={fetchLinkedFiles} selectedRows={selectedRows} />
      <WidgetDivider />
      <GeneralTable
        table={table}
        rowComponent={ItemActions}
        handleSelectedIds={setSelectedRows}
        selectRow
        loading={isLoading}
      />
    </Card>
  );
};
