import React from 'react';
import { useHistory } from 'react-router-dom';
import { MoreThanIcon } from 'components/Icons';
import { useStyles } from './styles';
import PropTypes from 'prop-types';

export const NewAdminHeaderTitle = ({ buttonName, section, variant }) => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <>
      {variant === 'admin' && (
        <div className={classes.container}>
          <button className={classes.button} onClick={() => history.push('/admin')} type="button">
            {buttonName}
          </button>
          {section && (
            <>
              <div className={classes.breadcrumb}>
                <MoreThanIcon size={16} />
              </div>
              <div className={classes.section}>{section}</div>
            </>
          )}
        </div>
      )}
    </>
  );
};

NewAdminHeaderTitle.propTypes = {
  buttonName: PropTypes.string,
  section: PropTypes.string,
  variant: PropTypes.string,
};

NewAdminHeaderTitle.defaultProps = {
  buttonName: '',
  section: '',
  variant: 'admin',
};
